import React, { useState, useEffect } from 'react';
import { HeadFC, Link as GatsbyLink } from 'gatsby';
import { useCart } from 'react-use-cart';

// Utils
import { itemsFormat, trackAnalytic, trackFB } from 'src/utils/gtag';

// Components
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';
import Section from 'fatcat-ui-library/components/Molecules/Section';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';

// Assets
import logo from 'src/assets/images/logo.svg';
import bad from 'src/assets/images/icons/thank-you/bad.svg';
import good from 'src/assets/images/icons/thank-you/good.svg';
import excellent from 'src/assets/images/icons/thank-you/excellent.svg';
import heart from 'src/assets/images/icons/thank-you/heart.svg';
import text from 'src/assets/images/icons/thank-you/text.svg';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const ThankYouPage = () => {
	const mixpanel = useMixpanel();
	const [reviewed, setReviwed] = useState(false);
	const { emptyCart, items, cartTotal } = useCart();

	useEffect(() => {
		if (items.length) {
			trackAnalytic('purchase', {
				currency: 'USD',
				transaction_id: new Date().toISOString(), // TO-DO: Fix this for real transaction id
				value: cartTotal,
				items: itemsFormat(items),
			});
			trackAnalytic('conversion', {
				send_to: process.env.GATSBY_GTAG_ADS_SEND_TO,
				currency: 'USD',
				transaction_id: new Date().toISOString(), // TO-DO: Fix this for real transaction id
				value: cartTotal,
				items: itemsFormat(items),
			});
			trackFB('Purchase', {
				value: cartTotal,
				currency: 'USD',
				content_type: 'product',
				contents: itemsFormat(items),
			});
			mixpanel.track('Purchase Completed', {
				amount: cartTotal,
				items,
			});

			emptyCart();
		}
	}, [items, cartTotal, emptyCart, mixpanel]);

	return (
		<main>
			<Section
				paddingY="s120"
				tablet={[{ paddingY: 's80' }]}
			>
				<Center>
					<Flex
						direction="column"
						alignItems="center"
						maxW="680px"
					>
						<Image
							src={logo}
							alt="Enjoy Good Health Logo"
							width="40px"
							height="48px"
							w="40px"
							marginBottom="s32"
							tablet={[{ marginBottom: 's24' }]}
						/>
						<Heading
							as="h1"
							w="100%"
							marginBottom="s48"
							tablet={[{ marginBottom: 's32' }]}
						>
							Thank you for your purchase!
						</Heading>
						<Paragraph
							marginBottom="s48"
							letterSpacing="1px"
							fontSize="s24"
							tablet={[{ marginBottom: 's32' }]}
						>
							We have received your order and are processing it now. You will receive an email confirmation shortly with all your order details.
						</Paragraph>
						<Paragraph
							marginBottom="s48"
							letterSpacing="1px"
							tablet={[{ marginBottom: 's32' }]}
						>
							{'If you have any questions or concerns about your purchase, please don’t hesitate to contact our customer service team at '}
							<Link
								to="mailto:support@enjoygoodhealth.com."
								textDecoration="underline"
							>
								support@enjoygoodhealth.com.
							</Link>
						</Paragraph>
						<Paragraph
							marginBottom="s56"
							fontSize="s18"
							fontWeight="light"
							letterSpacing="1px"
							tablet={[{ marginBottom: 's40' }]}
						>
							Thanks for making our small business grow - we are wholeheartedly grateful for that!
						</Paragraph>
						<Flex
							direction="column"
							backgroundColor="light"
							padding="s32"
							marginBottom="s40"
							borderRadius="40px"
							tablet={[{ marginBottom: 's32' }]}
						>
							<Paragraph
								textAlign="center"
								fontWeight="semiBold"
								marginBottom="s16"
							>
								Rate your experience
							</Paragraph>
							<Paragraph
								size="small"
								textAlign="center"
								marginBottom="s32"
								letterSpacing="1px"
							>
								How would you rate your overall experience with our website and the ordering process?
							</Paragraph>
							<Flex justifyContent="center" gap="24px">
								{!reviewed ? (
									[
										{
											img: bad,
											title: 'Bad',
										},
										{
											img: good,
											title: 'Good',
										},
										{
											img: excellent,
											title: 'Excellent',
										},
									].map((item, index) => (
										<Flex
											key={index} // eslint-disable-line
											direction="column"
											w="fit-content"
											alignItems="center"
											minW="68px"
											gap="8px"
										>
											<Button
												variant="noStyle"
												boxShadow="0px 1px 15px 2px rgb(132, 105, 105, 0.1)"
												borderRadius="70px"
												border="transparent 1px solid"
												hover={[{ borderColor: 'primary' }]}
												onClick={(e) => {
													setReviwed(true);
													// TODO: add review analitics for reviews
													// trackAnalytic()
												}}
											>
												<Image
													src={item.img}
													alt={item.title}
													width="49px"
													height="63px"
													w="49px"
												/>
											</Button>
											<Text
												fontSize="s16"
											>
												{item.title}
											</Text>
										</Flex>
									))
								) : (
									<Flex
										direction="column"
										gap="38px"
									>
										<Image
											src={heart}
											alt="Purple heart"
											width="40px"
											height="35px"
											w="40px"
										/>
										<Image
											src={text}
											alt="Thank you for your feddback"
											width="254px"
											height="24px"
											w="254px"
										/>
									</Flex>
								)}
							</Flex>
						</Flex>
						<Button
							as={GatsbyLink}
							to="/"
							w="fit-content"
						>
							Back to home
						</Button>
					</Flex>
				</Center>
			</Section>
		</main>
	);
};

export default ThankYouPage;

export const Head: HeadFC = (props) => {
	return (
		<HeadMeta
			meta={{
				pageTitle: 'Thank you | Enjoy good health',
				metaDescription: 'Thank you.',
			}}
		/>
	);
};
